import 'es6-promise/auto';
import 'normalize.css';
import './css/index.scss';
import $ from 'jquery';

console.log($);
if (process.env.NODE_ENV === 'development') {
  const VConsole = require('vconsole');
  new VConsole();
}
let pcTimer;// \u8ba1\u65f6\u5668\u8f7d\u4f53
let invitationCode;// \u9080\u8bf7\u7801
let pcTimerFlag;// \u8ba1\u65f6\u5668\u5f00\u5173
let isInvitationCode = false;// \u662f\u5426\u6709\u9080\u8bf7\u7801
let channel = '';
let channelName = '';
const queryObj = {};
function getBaseDesignWidth() {
  // \u83b7\u53d6\u57fa\u7840\u5bbd\u5ea6
  const width = document.documentElement.clientWidth;
  return width < 880 ? 880 : 1920;
}
function setPageInfo() {
  const baseSize = 192;
  // \u83b7\u53d6\u57fa\u7840\u5bbd\u5ea6
  const baseDesignWidth = getBaseDesignWidth();
  // \u54cd\u5e94\u5f0f\u5c55\u793apc\u6216h5
  const width = document.documentElement.clientWidth;
  if (width < 880) {
    $('#js-m-container').show();
    $('#js-pc-container').hide();
    $('#js-invitation-mask').removeClass('web-invitation-mask');
    $('#js-invitation-mask').addClass('m-invitation-mask');
  } else {
    $('#js-pc-container').show();
    $('#js-m-container').hide();
    $('#js-invitation-mask').addClass('web-invitation-mask');
    $('#js-invitation-mask').removeClass('m-invitation-mask');
  }
  $('#js-pc-invitation-confirm').text('\u6211\u77e5\u9053\u4e86');
  // \u6211\u77e5\u9053\u4e86,\u5173\u95eddialog
  // \u6dfb\u52a0pc-btn\u4e8b\u4ef6
  $('#js-pc-invitation-confirm').on('click', pcCloseInvitationCode);
  // \u8ba1\u7b97\u8bbe\u8ba1\u6bd4\u4f8b
  const scale = width / baseDesignWidth;
  document.documentElement.style.fontSize = `${baseSize * scale}px`;

  // \u9ad8\u5ea6\u54cd\u5e94\u5f0f
  const height = document.documentElement.clientHeight;
  if (height < 900 && height > 600) {
    const heightScale = height / 900;
    $('.pc .content').css({ transform: `scale(${heightScale})`, 'transform-origin': 'top' });
  }
}

// \u70b9\u51fb\u83b7\u53d6\u9080\u8bf7\u7801\u4e8b\u4ef6,\u6253\u5f00\u6d6e\u7a97
function pcGetInvitationCode() {
  $('#js-invitation-mask').show();
  let ifSurplusReport = 0;
  if (isInvitationCode) {
    // \u6709\u9080\u8bf7\u7801\u65f6,\u6253\u5f00dialog\u81ea\u52a8\u590d\u5236
    ifSurplusReport = 1;
    pcCopyCode();
  }
  // \u83b7\u53d6\u9080\u8bf7\u7801\u6309\u94ae\u70b9\u51fb\u4e0a\u62a5
  window.KK_SDK.report({
    operId: '1899000110202',
    ext1: ifSurplusReport,
    ext9: channel,
    ext10: channelName,
  });
}
// \u5173\u95ed\u6d6e\u7a97
function pcCloseInvitationCode() {
  $('#js-invitation-mask').hide();
  $('#js-pc-copy-toast').fadeOut(0);
  pcTimerFlag = false;
  clearTimeout(pcTimer);
}
// \u5c55\u793a\u590d\u5236\u6210\u529f\u7684toast
function pcShowCopyToast(content, backgroundImage) {
  if (pcTimerFlag) return;
  const addClass = backgroundImage;
  let delClass;
  if (backgroundImage === 'error') {
    delClass = 'success';
  } else {
    delClass = 'error';
  }
  // \u6839\u636e\u4f20\u53c2\u8bbe\u7f6eicon\u6837\u5f0f\u4e0etoast\u5185\u5bb9
  $('#js-invitation-toast-icon')
    .addClass(`invitation-mask-dialog-toast-content-icon-${addClass}`);
  $('#js-invitation-toast-icon')
    .removeClass(`invitation-mask-dialog-toast-content-icon-${delClass}`);
  $('#js-invitation-toast-item').text(content);
  // \u5c55\u793atoast
  pcTimerFlag = true;
  $('#js-pc-copy-toast').fadeIn(700);
  pcTimer = setTimeout(() => {
    $('#js-pc-copy-toast').fadeOut(700);
    pcTimerFlag = false;
  }, 1000);
}
// \u6267\u884c\u590d\u5236\u9080\u8bf7\u7801
function pcCopyCode() {
  navigator.clipboard.writeText(invitationCode)
    .then(() => {
      pcShowCopyToast('\u9080\u8bf7\u7801\u5df2\u590d\u5236', 'success');
    })
    .catch(() => {
      // clipboard API\u4e0d\u517c\u5bb9
      const input = document.getElementById('js-copy-input');
      // \u6dfb\u52a0\u5185\u5bb9
      input.value = invitationCode;
      // \u805a\u7126
      input.focus();
      // \u9009\u62e9\u9700\u8981\u590d\u5236\u7684\u6587\u672c
      if (input.setSelectionRange) {
        input.setSelectionRange(0, input.value.length);
      } else {
        input.select();
      }
      const result = document.execCommand('copy');
      if (result) {
        pcShowCopyToast('\u9080\u8bf7\u7801\u5df2\u590d\u5236', 'success');
      } else {
        pcShowCopyToast('\u5931\u8d25,\u8bf7\u624b\u52a8\u590d\u5236', 'error');
      }
    });
}
// \u6709\u9080\u8bf7\u7801\u65f6\u6267\u884c
function pcIsCodeShow(code) {
  $('#js-pc-is-invitation-code').show();
  $('#js-pc-is-code-copy').show();
  $('#js-pc-is-body').show();
  $('#js-pc-no-body').hide();
  $('#js-pc-is-code-text').text('\u5df2\u81ea\u52a8\u590d\u5236~ \u6ce8\u518c\u65f6\u586b\u5199\u8be5\u9080\u8bf7\u7801\u5c31\u53ef\u4ee5\u4e00\u8d77\u73a9\u5566');
  $('#js-pc-is-code-title').text('NOKNOK\u9080\u8bf7\u7801');
  $('#js-pc-is-invitation-code').text(code);
  invitationCode = code;
}
// \u65e0\u9080\u8bf7\u7801\u65f6\u6267\u884c
function pcIsCodeHide() {
  $('#js-pc-is-invitation-code').hide();
  $('#js-pc-is-code-copy').hide();
  $('#js-pc-is-body').hide();
  $('#js-pc-no-body').show();
  $('#js-pc-is-code-text').text('\u76ee\u524d\u9080\u8bf7\u7801\u88ab\u62a2\u5149\u4e86\uff0c\u4e0b\u6b21\u518d\u6765\u5427');
  $('#js-pc-is-code-title').text('\u9080\u8bf7\u7801\u88ab\u62a2\u5149\u5566');
}
// \u83b7\u53d6url\u53c2\u6570
function getQueryVariable() {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    const key = pair[0];
    const value = pair[1];
    queryObj[key] = value;
  }
}
// \u521d\u59cb\u5316\u51fd\u6570
function init() {
  // \u83b7\u53d6\u9080\u8bf7\u7801,\u5e76\u8bbe\u7f6e
  const { KK_SDK } = window;
  getQueryVariable();
  channel = queryObj['c-channel'];
  channelName = queryObj['c-channel-name'];
  KK_SDK.http.request({
    url: 'knockknock.UserInviteSrv/GetAdminValidInviteCode',
    data: {},
  })
    .then((data) => {
      console.log(data, '111data');
      if (data.is_show_invite_code) {
        // \u5f00\u542f\u529f\u80fd\u65f6
        // pc\u83b7\u53d6\u9080\u8bf7\u7801\u7ed1\u5b9a\u4e8b\u4ef6
        $('#js-pc_invitation_btn').on('click', pcGetInvitationCode);
        $('#js-m_invitation_btn').on('click', pcGetInvitationCode);
        // pc \u624b\u52a8\u590d\u5236
        $('#js-is-code-copy-area').on('click', pcCopyCode);
        // \u5f00\u542fpc\u9080\u8bf7\u7801
        $('#js-pc-invitation-btn-area').show();
        // \u9080\u8bf7\u7801\u6309\u94ae\u66dd\u5149\u4e0a\u62a5
        KK_SDK.report({
          operId: '1899000110102',
          ext9: channel,
          ext10: channelName,
        });
        // \u5f00\u542fm\u9080\u8bf7\u7801
        $('#js-m-is-code-invitation').show();
        $('#js-m-is-code-invitation').css('display', 'flex');
        if (data.valid_code) {
          // \u6709\u53ef\u7528\u9080\u8bf7\u7801\u65f6
          pcIsCodeShow(data.valid_code);
          isInvitationCode = true;
        } else {
          // \u65e0\u53ef\u7528\u9080\u8bf7\u7801\u65f6
          pcIsCodeHide();
          isInvitationCode = false;
        }
      }
    })
    .catch((err) => {
      if (err.data.ret === 131006) {
        $('#js-pc_invitation_btn').on('click', pcGetInvitationCode);
        $('#js-m_invitation_btn').on('click', pcGetInvitationCode);
        // pc \u624b\u52a8\u590d\u5236
        $('#js-is-code-copy-area').on('click', pcCopyCode);
        // \u5f00\u542fpc\u9080\u8bf7\u7801
        $('#js-pc-invitation-btn-area').show();
        $('#js-m-is-code-invitation').show();
        $('#js-m-is-code-invitation').css('display', 'flex');
        pcIsCodeHide();
        isInvitationCode = false;
      }
    });

  // \u9875\u9762\u66dd\u5149\u4e0a\u62a5
  if (document.readyState === 'interactive') {
    KK_SDK.report({
      operId: '1899000110101',
      ext9: channel,
      ext10: channelName,
    });
  }
}

setPageInfo();
window.addEventListener('resize', () => {
  setPageInfo();
});
init();

